<script>
import {deleteDriverSafeSignatureApi} from "@/www/urls/driverLearning";

export default {
    name: "delete-popover",
    props: {
        info: Object
    },
    data() {
        return {
            visible: false
        }
    },
    methods: {
        async deleteDriverSafeSignature() {
            this.loading = true;
            const ret = await deleteDriverSafeSignatureApi({id: this.info.id})
            if (!ret.success) {
                this.loading = false;
                this.visible = false;
                return this.$message.error(ret.errors[0].message)
            }
            setTimeout(() => {
                this.$emit('onRefresh')
            }, 200)
        },
    }
}
</script>

<template>
    <el-popover
        placement="left-start"
        width="300"
        v-model="visible">
        <h4>确定撤销签名？</h4>
        <p class="color-gary mt10">撤销签名后，司机将再次收到责任书内容，并重新签署。</p>
        <div style="text-align: right; margin: 0">
            <el-button type="text" size="small" @click="visible = false">取消
            </el-button>
            <el-button type="primary" size="small" @click="deleteDriverSafeSignature">确定
            </el-button>
        </div>
        <el-link slot="reference" type="danger" :underline="false">
            撤销签名
        </el-link>
    </el-popover>
</template>

<style scoped lang="scss">

</style>
